.theme-text {
    color: #1d71b8;
  }
  
  .card-img-top {
    height: 200px;
    width: 100%;
  }
  .text-center{
    font-size: 27px;
  }
  .card {
    z-index: 1;
    overflow: hidden;
    transition: 0.5s;
  }
  .card:hover {
    color: white;
  }
  .card::before {
    content: "";
    position: absolute;
    left: -100%;
    top: 0%;
    background: #1d71b8;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: -1;
  }
  .card:hover::before {
    left: 0%;
    color: white;
  }
  .container-fluid {
    background-image: url();
    background-position: center;
    background-size: cover;
  }
 .card-body ul li{
    padding: 8px 0;
    list-style: circle;
  }