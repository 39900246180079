
.navbar{
    box-shadow:0px 12px 18px -4px rgb(0 0 0 / 30%);
    background-color: #fff !important;
    position: sticky;
    top: 0%;
    z-index: 999;
}
.nav-link{
    font-size: 18px;
    font-weight: bold;
}
.nav-link:hover{
    color: #1d71b8;
}
.nav-link:focus{
    color: #1d71b8;
}
.nav-item{
  margin: 10px;
}
.btn{
    color: black;
    font-weight: bold;
    border-color: #1d71b8;
    transition: 0.5s;
}
.btn:hover{
    background-color: #1d71b8;
}
.liens{
    text-decoration: none;
    color: black;
}
.liens:hover{
    color: #fff;
}
.logo{
height: 102px;
width: 130;
margin-left: 30px;
}