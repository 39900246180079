.hero-header {
    background: url(../../../assets/hero1.jpeg) no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 88vh;
}

.btn:hover {
    color: white;
    opacity: 0.8;
}

h2 {
    text-align: center;
    margin: 30px 0;
    font-size: 45px;
    font-weight: bolder;
    color: white;
    margin-top: 140px;
}

h2 span {
    animation: glow 2s ease-in-out infinite;
}

@keyframes glow {

    0%,
    100% {
        color: #1d71b8;
        text-shadow: 0 0 12px #315e5f, 0 0 50px #315e5f, 0 0 100px #315e5f;
    }

    10%,
    90% {
        color: #ffffff;
        text-shadow: none;
    }
}

h2 span:nth-child(2) {
    animation-delay: 0.25s;
}

h2 span:nth-child(3) {
    animation-delay: 0.50s;
}

h2 span:nth-child(4) {
    animation-delay: 0.75s;
}

@media (max-width: 414px) {
    .hero-header {
        background: url(../../../assets/hero2.jpeg) no-repeat;
        background-size: 650px;
        height: 40%;
        width: 100%;
        
    }
    .text-lg-start h2{
        font-size: 21px;
    }

}