.heading{
    text-align: center;
    margin-top: 40PX;;
}
.heading h1{
    font-size: 30px;
    margin-bottom: 20px;
    color: rgb(26, 26, 26);
}
.text_about p{
  font-weight: 700;
  letter-spacing: 1px;
}
video{
  margin-top: 30px;
  margin-bottom: 60px;
}
.about1{
    display: flex;
    align-items: center;
    width: 75%;
    margin: auto;
    
}
.about1 img{
    flex: 0;
    max-width: 50%;
    margin: 30px;
    border: 2px solid rgb(186, 186, 186);
    box-shadow: 12px 12px 2px 1px rgba(199, 196, 196, 0.772);
}
.content{
    width: 100%;
}
.content h3{
    color: #1d71b8;
    font-size: 24px;
    margin: 15px 0;
}
.content p{
    font-size: 18px;
    color: #666;
    line-height: 1.5;
}
.read-more-btn{
    display: inline-block;
    color: white;
    background-color: #1d71b8;
    text-decoration: none;
    margin: 20px 0;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 500;
    transition: 0.5s ease;
}
.read-more-btn:hover{
    opacity: 0.8;
    color: white;
}

@media(max-width:768px){
    .about1{
        flex-direction: column;
    }
    .about1 img{
        flex: 0 100%;
        max-width: 100%;
    }
    .content{
        flex: 0 100%;
        max-width: 100%;
        padding: 15px;
    }

}
@media (max-width: 637px) { 
  .video{
      width: 360px;
      height: 300px !important;
      
  }
  .titlesp h1{
    font-size: 17px ;
  }

}

.mains{
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fcfcfc;
  margin-bottom: 70px;
}

.profile-card{
    position: relative;
    width: 220px;
    height: 230px;
    background: #fff;
    padding: 30px;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .6s;
    margin: 0 25px;
  }
  .profile-card:hover{
    border-radius: 10px;
    height: 260px;
  }
  .profile-card .img{
    position: relative;
    width: 100%;
    height: 100%;
    transition: .6s;
    z-index: 99;
  }
  .profile-card:hover .img{
    transform: translateY(-60px);
  }
  .img img{
    width: 100%;
    border-radius: 50%;
    box-shadow: 0 0 22px #3336;
    transition: .6s;
  }
  .profile-card:hover img{
    border-radius: 10px;
  }
  .caption{
    text-align: center;
    transform: translateY(-80px);
    opacity: 0;
    transition: .6s;
  }
  .profile-card:hover .caption{
    opacity: 1;
  }
  .caption h3{
    font-size: 21px;
  }
  .caption p{
    font-size: 16px;
    color: #0c52a1;
    margin: 2px 0 9px 0;
  }
  .caption .social-links a{
    color: #333;
    margin-right: 8px;
    font-size: 21px;
    transition: .6s;
  }
  .social-links a:hover{
    color: #0c52a1;
  }
  .titlesp{
 
    color: #1d71b8 !important;
    text-align: center;
    padding: 20px 0;
 
  }