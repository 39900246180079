.containeS{
    width: 100%;
    margin: 30px 0;
}
.titles{
    height: 300px;
    background: url('../../../assets/rdv.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    margin-top: 20px;
	background-color: rgb(227, 227, 227);
    opacity: 0.7;
}


.titles h1{
    padding: 54px 0;
    color: black;
    
    font-weight: bold;
}
.titles span{
    font-size: 18px;
    color: #1d71b8;
}


.about-sec{
	display: flex;
	padding: 3rem 0;
	width: 100%;
	justify-content: center;
	background: #fcfcfc;
    margin: 40px 0;
}
.about-img{
	width: 640px;
	height: 480px;
	margin: 0 3rem;
	
}
.about-img img{
	height: 100%;
	width: 100%;
	border: 2px solid rgb(186, 186, 186);
	box-shadow: 12px 12px 2px 1px rgba(199, 196, 196, 0.772);
}
.about-intro{
	color: rgb(40, 40, 40);
	width: 500px;
	height: 500px;
	border-left: 3px solid #1d71b8;
	padding-left: 2rem;
	margin: 0 3rem;
}
.about-intro p{
	background: url(../../../assets/shape.png) no-repeat;
	background-size: cover;
	background-position: center;
	margin-top: 1.5rem;
	font-size: 16px;
	
	font-weight: 700;
	letter-spacing: 1px;
	
}
@media only screen and (max-width: 900px) {
	.about-sec{
		flex-direction: column;
		align-items: center;
	}
	.about-img{
		width: 100%;
		height: 300px;
	}
	.about-intro{
		width: 100%;
		height: 100%;
		border-top: 3px solid #1d71b8;
		border-left: none;
		padding: 1rem;
		margin-top: 2rem;
	}
	.about-intro h3,p{
		width: 100%;
		

	}
	.about-intro p{
		font-size: 12px;
	}


}
.times span{
	font-weight: 300;
	color: #1d71b8;
}
.about-section{
    background: url(../../../assets/cabnier.jpeg) no-repeat left;
    background-size: 50%;
    overflow: hidden;
    padding: 100px 0;
	margin: 30px 0;
}

.inner-container{
    width: 55%;
    float: right;
    background-color: #fefefe;
    padding: 150px;
}

.inner-container h1{
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 900;
}
.inner-container ul{
	list-style: circle;
}
.inner-container ul li{
	font-weight: bold; 
	letter-spacing: 1px;
	font-size: 18px;
	margin-top: 25px;
}
.inner-container span{
	padding-left: 10px;
	font-weight: 300;
	font-size: 17px;
}


.content2 {
	display: flex;
	justify-content: baseline;
	align-items: center;
	background-color: #fcfcfc;
	margin: 30px 0;
	padding: 40px 0;
}
.content2 ul{
	list-style: circle;
	padding: 30px;
	margin-left: 50px;

}
.content2 ul li{
	padding: 20px 0;
	font-size: 20px;
	font-weight: 800;
	color: rgb(77, 77, 77);
}

@media screen and (max-width:1200px){
    .inner-container{
        padding: 80px;
    }
}

@media screen and (max-width:1000px){
    .about-section{
        background-size: 100%;
        padding: 100px 40px;
    }
    .inner-container{
        width: 100%;
    }
}

@media screen and (max-width:600px){
    .about-section{
        padding: 0;
    }
    .inner-container{
        padding: 60px;
    }
}