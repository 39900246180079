.link-rendes{
    text-decoration: none;
    color: #fff;
    background-color: #1d71b8;
    margin: 30px 0;
    padding: 5px 12px;
    font-weight: bolder;
    box-shadow: 12px 12px 2px 1px rgba(87, 87, 86, .6);
    transition: 0.5s;
    letter-spacing: 1px;
    border-radius: 5px;
    font-size: 18px;
}

.link-rendes:hover{
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 30%);
    background-color: #575756;
    color: #fff;
}
