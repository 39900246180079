 .containerS{
    width: 100%;
    
}
.titre{
    height: 300px;
    background: url('../../../assets/rdv.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    margin-top: 20px;
    background-color: rgb(0, 0, 0);
    opacity: 0.5;
}

.titre h1{
    padding: 102px 0;
    color: black;
    font-weight: bold;
}
.titre span{
    font-size: 19px;
    color: #1d71b8;
}


.error{
    color: rgb(231, 118, 118);
}
.containers{
    width: 100%;
    min-width: 420px;
    padding: 35px 50px;
    border-radius: 10px;
}
h1{
    font-size: 30px;
    text-align: center;
    color: rgb(0, 0, 0);
    letter-spacing: 1px;
}
form{
    width: 100%;
    position: relative;
    margin: 30px auto 0 auto;
}
.co{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    grid-gap: 20px 30px;
    margin-bottom: 20px;
}
label{
    color: #1c093c;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
}
textarea,
input{
    width: 100%;
    font-weight: 400;
    padding: 8px 10px;
    border-radius: 5px;
    border: 1.2px solid #c4cae0;
    margin-top: 5px;
}
textarea{
    resize: none;
}
textarea:focus,
input:focus{
    outline: none;
    border-color: #6f6df4;
}
.bnt{
    border: none;
    padding: 10px 20px;
    background: linear-gradient( 130deg, #6f6df4,  #4c46f5);
    color: #ffffff;
    border-radius: 3px;
    width: 140px;
}
.mapouter{
    position:relative;
    text-align:right;
    height:100%;
    width:100%;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:100%;width:100%;
}